*{
/* background: #fff none repeat scroll 0 0;
color: #444;
font-family: 'Open Sans', sans-serif;
font-size: 14px;
text-align: left;
overflow-x: hidden;
line-height: 22px; */
}
.alignCenter{
  text-align: center;
}
.advertise{
  width:70%;
  padding-top: 5px;
}
.width-100{
  width:70%;
}
.paddingTop-5{
padding-top: 5px;
}
.head1{
  height: 1px;
}
.head-2{
  font-size:30px;
  cursor:pointer;
}
.gl-1{
  width:100%;
  padding-top: 5px;
  padding-bottom: 15px;
}
.gl-2{
  width:100%;
  padding-top: 5px;
  padding-bottom: 15px;
}
.disIn{
  display: initial;
}

.slick-prev:before, .slick-next:before {

  font-size: 35px!important;
  color: #b9955b!important;
}
.gallery-list {
    background: #000;
    color: #fff;
    position: relative;
    margin-top: 3rem;
}

.img-responsive, .thumbnail>img, .thumbnail a>img {
    width: 100%;
  height:100%;
  display: block;
    max-width: 100%;
    height: auto;
  object-fit: cover;
  vertical-align: middle;
}
.gallery-counter {
    position: absolute;
    top: 15px;
    left: 12px;
    background: #0000008c;
    color: #fff;
    padding: 5px 20px 5px;
    border-radius: 40px;
    font-size: 28px;
}
.gallery-counter span {
    font-size: 20px;
    color: #d8d8d8;
}
.gallery-list .title {
    color: #fff;
    padding: 16px 10px;
    font-size: 18px;
    line-height: 1.6;
}
.gallery-list .title p{
    color: #fff;
    font-size: 18px;
    line-height: 1.6;
}

.gallery-list .title h2{
    margin-top: 0px;
    margin-bottom: 5px;
  font-size: 20px;
}

.gallery-list .title h2 span{
    margin-top: 0px;
    margin-bottom: 5px;
  font-size: 20px;
  color: #ffeb39;
}
